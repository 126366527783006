"use client";
import { AnimatePresence } from "framer-motion";
import { MaterialSymbol } from "material-symbols";
import { useEffect, useState } from "react";
import { CtaQueryResult } from "../../../sanity/queries";
import LinkSolstice from "../../shared/LinkSolstice/LinkSolstice";
import { MaterialIcon } from "../../shared/MaterialIcon/MaterialIcon";
import styles from "./EmergencyBanner.module.scss";
import { set } from "react-hook-form";

interface EmergencyBannerProps {
	bannerId?: string | null;
	cta?: CtaQueryResult | null;
	icon?: string | null;
	message?: string | null;
}

export const EmergencyBanner = ({ cta, icon, message }: EmergencyBannerProps) => {
	const [isDismissed, setIsDismissed] = useState(false);

	return (
		!isDismissed && (
			<div className={styles["emergency"]}>
				<div className={styles["emergency__inner"]}>
					<div className={styles["emergency__content-wrapper"]}>
						<div className={styles["emergency__content"]}>
							{icon && <MaterialIcon name={icon as MaterialSymbol} />}

							{message && <span className={styles["emergency__message"]}>{message}</span>}
						</div>
						{cta && cta.link && (
							<LinkSolstice
								className={styles["emergency__link"]}
								href={cta.link}
							>
								<span className={styles["emergency__link-text"]}>{cta.label}</span>

								<MaterialIcon
									className={styles["emergency__link-arrow"]}
									name="arrow_right_alt"
								/>
							</LinkSolstice>
						)}
					</div>

					<button
						className={styles["emergency__dismiss"]}
						aria-label={`Dismiss message: ${message}`}
						onClick={() => setIsDismissed(true)}
					>
						<MaterialIcon name="close" />
					</button>
				</div>
			</div>
		)
	);
};
