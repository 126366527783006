"use client";

import { datadogRum } from "@datadog/browser-rum";

datadogRum.init({
	applicationId: process.env.NEXT_PUBLIC_DATADOG_APP_ID ?? "",
	clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN ?? "",
	site: process.env.NEXT_PUBLIC_DATADOG_SITE ?? "ap1.datadoghq.com",
	service: "solstice-web",
	env: process.env.VERCEL_ENV ?? "development",
	// Specify a version number to identify the deployed version of your application in Datadog
	// version: '1.0.0',
	sessionSampleRate: 100,
	sessionReplaySampleRate: 20,
	trackUserInteractions: true,
	trackResources: true,
	trackLongTasks: true,
	defaultPrivacyLevel: "mask-user-input",
});

export const DataDogInit = () => {
	return null;
};
